import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CmsModel } from 'client/data/models/cms';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { INDUSTRY_CENTER_GLOBAL_NAV_PATH } from 'site-modules/shared/constants/industry-center';
import { IndustryCenterGlobalNavigation } from 'site-modules/shared/components/industry-center/industry-center-global-navigation/industry-center-global-navigation';
import { IndustryCenterFooter } from 'site-modules/shared/components/industry-center/industry-center-footer/industry-center-footer';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { SEOHead } from 'site-modules/shared/pages/seo';

export class IndustryCenterDecorator extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static preload(store) {
    const preloader = new ModelPreloader(store);
    preloader.resolve(`content["${INDUSTRY_CENTER_GLOBAL_NAV_PATH}"]`, CmsModel);

    return preloader.load();
  }

  render() {
    return (
      <div className="industry-page">
        <SEOHead {...this.props} />
        <ErrorBoundary>
          <IndustryCenterGlobalNavigation />
        </ErrorBoundary>
        {this.props.children}
        <IndustryCenterFooter />
      </div>
    );
  }
}
