import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import edmundsLogo from 'client/images/edmunds-logo-226.png';

import './edmunds-logo.scss';

const inlineEdmundsLogo = '/img/car-buying/steps/edmunds_logo_expert.svg';

/**
 * EdmundsLogo component
 *
 * @param  {string} props.className Context specific logo class name
 * @return {ReactElement} EdmundsLogo React element
 */
export function EdmundsLogo({ path, className, alt, trackingId, inline, inlineLogoHeight }) {
  const logoClassName = classnames(className, { 'edmunds-logo-inline': inline, 'edmunds-logo': !inline });
  const inlineLogo = <img src={getStaticImageUrl(inlineEdmundsLogo)} height={inlineLogoHeight} alt={alt} />;
  const logo = (
    <picture>
      <img src={edmundsLogo} height={18} alt={alt} loading="lazy" />
    </picture>
  );
  const logoImg = inline ? inlineLogo : logo;
  return path ? (
    <Link to={path} className={logoClassName} data-tracking-id={trackingId}>
      {logoImg}
    </Link>
  ) : (
    <span className={logoClassName}>{logoImg}</span>
  );
}

/**
 * EdmundsLogo props definition
 *
 * @type {Object}
 */
EdmundsLogo.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  alt: PropTypes.string,
  trackingId: PropTypes.string,
  inline: PropTypes.bool,
  inlineLogoHeight: PropTypes.number,
};

/**
 * EdmundsLogo props default values
 *
 * @type {Object}
 */
EdmundsLogo.defaultProps = {
  className: null,
  path: '/',
  alt: 'Edmunds',
  trackingId: 'homepage_click',
  inline: false,
  inlineLogoHeight: 24,
};
