import React from 'react';
import { NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

function getNavBarData(entriesToRender) {
  return !!entriesToRender && entriesToRender.childEntries
    ? entriesToRender.childEntries.filter(entry => entry.id === 'navigation').pop().childEntries
    : [];
}

export function renderNavEntries(entriesToRender, onClick) {
  const navBarData = getNavBarData(entriesToRender);
  if (!navBarData.length) {
    return [{ el: null }];
  }

  return navBarData
    .reduce((entries, mainNavEntry) => {
      switch (true) {
        case 'childEntries' in mainNavEntry:
          entries.push({
            id: mainNavEntry.id,
            el: (
              <NavItem key={mainNavEntry.id}>
                <NavLink className="heading-5 font-weight-normal py-0_25 px-0_5" href="#" onClick={onClick}>
                  <ContentFragment componentToUse="span" id={mainNavEntry.id} classes="d-inline-block py-1_5 px-0_5">
                    {mainNavEntry.contentMetadata.title}
                  </ContentFragment>
                </NavLink>
              </NavItem>
            ),
            children: (
              <div className="collapse-container d-flex">
                {mainNavEntry.childEntries.map(subNavEntry => (
                  <div className="collapse-content my-2 mx-2 px-3_5" key={subNavEntry.id}>
                    <h5 className="mb-1 text-white heading-5">{subNavEntry.contentMetadata.title}</h5>
                    {subNavEntry.links &&
                      subNavEntry.links.map(subNavLink => (
                        <div key={subNavLink.title}>
                          <Link to={subNavLink.href} className="text-white">
                            {subNavLink.title}
                          </Link>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            ),
          });
          break;
        case 'button' in mainNavEntry.contentMetadata && mainNavEntry.contentMetadata.button === 'true':
          entries.push({
            id: mainNavEntry.id,
            el: (
              <NavItem key={mainNavEntry.id}>
                <Button
                  className="heading-5 ml-lg-0_5 ml-0 my-lg-0 my-0_5 px-1 py-0_5"
                  color="secondary"
                  href={mainNavEntry.contentMetadata.url}
                >
                  <ContentFragment componentToUse="span" id={mainNavEntry.id}>
                    {mainNavEntry.contentMetadata.title}
                  </ContentFragment>
                </Button>
              </NavItem>
            ),
          });
          break;
        default:
          entries.push({
            id: mainNavEntry.id,
            el: (
              <NavItem key={mainNavEntry.id}>
                <NavLink
                  className="heading-5 font-weight-normal py-0_25 px-0_5"
                  href={mainNavEntry.contentMetadata.url}
                >
                  <ContentFragment componentToUse="span" id={mainNavEntry.id} classes="d-inline-block py-1_5 px-0_5">
                    {mainNavEntry.contentMetadata.title}
                  </ContentFragment>
                </NavLink>
              </NavItem>
            ),
          });
          break;
      }
      return entries;
    }, [])
    .slice();
}

export function renderNavBarEntries(entriesToRender, onClick) {
  const navBarData = getNavBarData(entriesToRender);
  if (!navBarData.length) {
    return [{ el: null }];
  }

  return navBarData
    .reduce((entries, mainNavEntry) => {
      switch (true) {
        case 'childEntries' in mainNavEntry:
          entries.push({
            id: mainNavEntry.id,
            el: (
              <NavItem key={mainNavEntry.id}>
                <NavLink className="heading-5 font-weight-normal px-0 py-0_5" href="#" onClick={onClick}>
                  <ContentFragment componentToUse="span" id={mainNavEntry.id}>
                    {mainNavEntry.contentMetadata.title}
                  </ContentFragment>
                  <span className="ml-0_25 icon-arrow-right3" aria-hidden />
                </NavLink>
              </NavItem>
            ),
            children: (
              <div className="collapse-container">
                {mainNavEntry.childEntries.map(subNavEntry => (
                  <div className="py-1" key={subNavEntry.id}>
                    <h5 className="mb-1 text-white heading-5 font-weight-normal">
                      {subNavEntry.contentMetadata.title}
                    </h5>
                    <hr className="my-1_5" />
                    {subNavEntry.links &&
                      subNavEntry.links.map(subNavLink => (
                        <div key={subNavLink.title} className="my-1">
                          <Link to={subNavLink.href} className="text-white">
                            {subNavLink.title}
                          </Link>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            ),
          });
          break;
        default:
          entries.push({
            id: mainNavEntry.id,
            el: (
              <NavItem key={mainNavEntry.id}>
                <NavLink className="heading-5 font-weight-normal px-0 py-1" href={mainNavEntry.contentMetadata.url}>
                  <ContentFragment id={mainNavEntry.id}>{mainNavEntry.contentMetadata.title}</ContentFragment>
                </NavLink>
              </NavItem>
            ),
          });
          break;
      }
      return entries;
    }, [])
    .slice();
}
