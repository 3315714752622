import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { Link } from 'site-modules/shared/components/link/link';
import { isEmpty } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { getHeader, getItems } from 'client/site-modules/industry-center/utils/utils';
import { HtmlVideoPlayer } from 'site-modules/shared/components/html-video-player/html-video-player';

import './feature-grid.scss';

const FEATURE_GRID_HEADER = 'feature-grid-header';

export function GridCard({ item }) {
  const entryContent = item.getAllMetadata();
  if (isEmpty(entryContent)) return null;

  const {
    'item-image': itemImage,
    'static-video': staticVideo,
    button: showButton,
    'item-description': itemDescription,
    'item-link-text': itemLinkText,
    'item-link-url': itemLinkUrl,
    'item-title': itemTitle,
  } = entryContent;

  return (
    <Col xs={12} md={6} lg={4}>
      <div className="grid-card d-flex flex-column align-items-center pb-2">
        {itemImage ? (
          <img src={itemImage} alt={itemTitle} />
        ) : (
          <HtmlVideoPlayer
            type={'video/quicktime'}
            src={staticVideo}
            preload={'auto'}
            className={'video-player'}
            width={250}
            controls
          />
        )}
        {itemTitle && (
          <ContentFragment componentToUse={'p'} classes="heading-6 mb-0_25 mb-md-0_5 pt-2 pt-md-0_75 text-center">
            {itemTitle}
          </ContentFragment>
        )}
        {itemDescription && (
          <ContentFragment componentToUse={'p'} classes="medium text-center mb-0_75">
            {itemDescription}
          </ContentFragment>
        )}
        {showButton === 'true' && itemLinkUrl && (
          <Button className="mt-auto" color="primary" href={itemLinkUrl} target="_blank">
            {itemLinkText}
          </Button>
        )}
        {showButton === 'false' && itemLinkUrl && (
          <Link className="btn btn-link text-primary medium mt-auto px-0" to={itemLinkUrl} target="_blank">
            {itemTitle}
          </Link>
        )}
      </div>
    </Col>
  );
}

GridCard.propTypes = {
  item: PropTypes.shape({}),
};
GridCard.defaultProps = {
  item: {},
};

export function FeatureGrid({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }
  const { headerText, subHeaderText } = getHeader(entry, FEATURE_GRID_HEADER);
  const gridCards = getItems(entry, FEATURE_GRID_HEADER);

  return (
    <Row className="feature-grid justify-content-center">
      <Col xs={12}>
        <h1 className="header heading-1 text-center text-gray-darker mb-2">{headerText}</h1>
        {subHeaderText && (
          <ContentFragment componentToUse={'div'} classes="display-lg text-center text-gray-darker pb-2 pb-md-1_5">
            {subHeaderText}
          </ContentFragment>
        )}
      </Col>
      <Col xs={12}>
        <Row>
          {gridCards.map(item => (
            <GridCard item={item} key={item.id} />
          ))}
        </Row>
      </Col>
    </Row>
  );
}

FeatureGrid.propTypes = {
  entry: PropTypes.shape({}),
};

FeatureGrid.defaultProps = {
  entry: {},
};
