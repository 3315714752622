import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { randomInt } from 'client/utils/seed-randomizers';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PHOTOFLIPPER_EVENTS } from 'site-modules/shared/constants/custom-events';
import {
  createPlayerStateChangeHandler,
  PAUSE_EVENT,
  PLAY_EVENT,
} from 'client/engagement-handlers/youtube-engagement-handler/youtube-engagement-handler';
import { YOUTUBE_PREVIEW_SIZE, getYoutubePreviewImage } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import './embedded-youtube.scss';

function onPlayerReady(autoplay) {
  return event => autoplay && event.target.playVideo();
}

function changeHandlerQueue(handler, seed) {
  return e => {
    handler(e);
    createPlayerStateChangeHandler(seed)(e);
  };
}

/**
 * Youtube doc recommended API loading
 * https://developers.google.com/youtube/iframe_api_reference
 */
function addAPIScript() {
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function youtubeIframeLoaded() {
  return typeof window !== 'undefined' && !!document.querySelector('[src="https://www.youtube.com/iframe_api"]');
}

function getPlayer({ autoplay, changeHandler, videoId, seed, startTime }) {
  return new window.YT.Player(`video-${videoId}-${seed}`, {
    height: '100%',
    width: '100%',
    videoId,
    playerVars: { rel: 0, start: startTime },
    events: {
      onReady: onPlayerReady(autoplay),
      onStateChange: changeHandler ? changeHandlerQueue(changeHandler, seed) : createPlayerStateChangeHandler(seed),
    },
  });
}

export function EmbeddedYoutube({
  className,
  children,
  loaderClassName,
  creativeId,
  videoId,
  videoTitle,
  thumbnailSrc,
  youtubeThumbnailSize,
  videoAspectRatio,
  lazyLoadPreview,
  loaderTrackingId,
  loaderTrackingValue,
  noPlayIcon,
  autoplay,
  autoplayOnChange,
  changeHandler,
  startTime,
  onPlayClick,
}) {
  const seed = useRef(randomInt());
  const player = useRef();
  const videoToLoad = useRef();
  const isLoaded = useRef(false);

  const playerProps = useMemo(() => ({ autoplay, changeHandler, videoId, startTime, seed: seed.current }), [
    autoplay,
    changeHandler,
    startTime,
    videoId,
  ]);

  const loadAndPlayVideo = useCallback(() => {
    player.current = window.YT
      ? getPlayer({ ...playerProps, autoplay: true })
      : (window.onYouTubeIframeAPIReady = () => {
          player.current = getPlayer(playerProps);
        });
  }, [playerProps]);

  const onModalToggle = useCallback(({ detail: { isModalOpen } }) => {
    if (player.current && isModalOpen) {
      player.current.pauseVideo();
    }
  }, []);

  const pauseOtherPlayers = useCallback(({ detail: { seed: currentSeed } }) => {
    if (player.current && seed.current !== currentSeed) {
      player.current.pauseVideo();
    }
  }, []);

  const playVideoToLoad = useCallback(() => {
    if (videoToLoad.current) {
      player.current.loadVideoById(videoToLoad.current);
      videoToLoad.current = null;
    }
  }, []);

  useEffect(() => {
    // If autoplay, don't lazy load
    if (autoplay) {
      window.onYouTubeIframeAPIReady = () => {
        player.current = getPlayer(playerProps);
      };
    }

    if (!youtubeIframeLoaded()) {
      addAPIScript();
    } else if (autoplay) {
      // Autoplay the video if it the api has been added before as onYouTubeIframeAPIReady only gets called once per API load
      loadAndPlayVideo();
    }

    EventToolbox.on(PHOTOFLIPPER_EVENTS.TOGGLE, onModalToggle);
    EventToolbox.on(PLAY_EVENT, pauseOtherPlayers);
    EventToolbox.on(PAUSE_EVENT, playVideoToLoad);

    return () => {
      EventToolbox.off(PHOTOFLIPPER_EVENTS.TOGGLE, onModalToggle);
      EventToolbox.off(PLAY_EVENT, pauseOtherPlayers);
      EventToolbox.off(PAUSE_EVENT, playVideoToLoad);

      delete player.current;
      delete window.onYouTubeIframeAPIReady;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded.current) {
      return;
    }

    if (get(player, 'current.getVideoData')) {
      videoToLoad.current = { videoId, startSeconds: startTime };

      // If playing: pause -> wait for custom event (PAUSE_EVENT) -> play next video
      if (player.current.getPlayerState() === window.YT.PlayerState.PLAYING) {
        player.current.pauseVideo();
      } else {
        playVideoToLoad();
      }
    } else if (!player.current && autoplayOnChange && videoId) {
      loadAndPlayVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, startTime]);

  useEffect(() => {
    isLoaded.current = true;
  }, []);

  function onPlay() {
    if (onPlayClick) {
      onPlayClick();
    }
    loadAndPlayVideo();
  }

  const videoFragment =
    typeof children === 'function' ? (
      children({ onPlayClick: onPlay })
    ) : (
      <Fragment>
        <img
          className="placeholder-img w-100"
          src={thumbnailSrc || getYoutubePreviewImage(videoId, youtubeThumbnailSize)}
          alt={`${videoId} - ${videoTitle}`}
          loading={classnames({ lazy: lazyLoadPreview })}
        />
        <button
          aria-label="Play"
          className={classnames('loader', loaderClassName, { 'no-icon': noPlayIcon })}
          onClick={onPlay}
          data-tracking-id={loaderTrackingId}
          data-tracking-value={loaderTrackingValue}
        />
      </Fragment>
    );

  return (
    creativeId &&
    (videoAspectRatio ? (
      <AspectRatioContainer aspectRatio={videoAspectRatio} className="embedded-youtube-container">
        <div id={`video-${videoId}-${seed.current}`} className={classnames('embedded-youtube', className)}>
          {videoFragment}
        </div>
      </AspectRatioContainer>
    ) : (
      <div id={`video-${videoId}-${seed.current}`} className={classnames('embedded-youtube w-100 h-100', className)}>
        {videoFragment}
      </div>
    ))
  );
}

EmbeddedYoutube.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  creativeId: PropTypes.string.isRequired,
  onPlayClick: PropTypes.func,
  children: PropTypes.func,
  videoAspectRatio: PropTypes.string,
  thumbnailSrc: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  startTime: PropTypes.number,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  loaderTrackingId: PropTypes.string,
  loaderTrackingValue: PropTypes.string,
  youtubeThumbnailSize: PropTypes.oneOf(Object.values(YOUTUBE_PREVIEW_SIZE)),
  autoplayOnChange: PropTypes.bool,
  lazyLoadPreview: PropTypes.bool,
  noPlayIcon: PropTypes.bool,
};

EmbeddedYoutube.defaultProps = {
  onPlayClick: null,
  children: null,
  videoAspectRatio: '16:9',
  thumbnailSrc: null,
  changeHandler: null,
  startTime: undefined,
  autoplay: false,
  youtubeThumbnailSize: YOUTUBE_PREVIEW_SIZE.MAX_RES,
  autoplayOnChange: false,
  lazyLoadPreview: false,
  className: null,
  loaderClassName: 'w-100 h-100 position-absolute p-0 border-0 no-focus',
  loaderTrackingId: null,
  loaderTrackingValue: null,
  noPlayIcon: null,
};
