import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { connect } from 'react-redux';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { TruncatedText } from 'site-modules/shared/components/truncated-text/truncated-text';
import { DEFAULT_ALIGNMENT_CLASS, DEFAULT_BG_COLOR, MAX_TEXT_LENGTH } from './constants';

export function ImageTextSlideComponent({ slideItem, isMobile }) {
  const {
    'button-url': ctaUrl,
    'button-title': ctaTitle,
    'align-horizontal': alignment,
    button,
    header,
    text,
  } = slideItem;
  const bgImageName = isMobile ? 'media-image-mobile' : 'media-image';
  const bgImage = slideItem[bgImageName];
  const alignClass = alignment && alignment !== 'center' ? `content-align-${alignment}` : DEFAULT_ALIGNMENT_CLASS;
  const bgColor = slideItem['bg-color'] || DEFAULT_BG_COLOR;

  return (
    <div className="slide-image-text-carousel wrap-flex carousel-item">
      <div className="photo" style={bgImage ? { backgroundImage: `url(${bgImage})` } : { backgroundColor: bgColor }}>
        <div className="d-flex justify-content-center h-100 flex-column">
          {(header || text || button) && (
            <div
              className={classNames('col-sm-11 col-md-8 col-lg-7 content-container carousel-captions px-2', alignClass)}
            >
              {header && (
                <ContentFragment componentToUse={'p'} classes="text-white h3">
                  {header}
                </ContentFragment>
              )}
              {text && (
                <div className="text-white font-weight-normal mt-2 pos-r">
                  <TruncatedText text={text} maxTextLength={MAX_TEXT_LENGTH} btnClassName="text-uppercase" />
                </div>
              )}
              {button && ctaTitle && (
                <div className="button-wrapper mt-2 pos-r">
                  <Button size="md" outline className="cta-btn" href={ctaUrl}>
                    <span className="text-white">{ctaTitle}</span>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ImageTextSlideComponent.propTypes = {
  slideItem: PropTypes.shape({
    button: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
    'button-url': PropTypes.string,
    'button-title': PropTypes.string,
    'media-image': PropTypes.string,
    'align-horizontal': PropTypes.string,
    'bg-color': PropTypes.string,
  }),
  isMobile: PropTypes.bool,
};

ImageTextSlideComponent.defaultProps = {
  slideItem: null,
  isMobile: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const ImageTextSlide = connect(mapStateToProps)(ImageTextSlideComponent);
