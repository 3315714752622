export const STORIES_INFO_MAP = {
  'Hero-Alt': {
    story: { title: 'DEAL-4', href: 'https://edmunds.atlassian.net/browse/DEAL-4' },
    mr: {
      title: 'Hero Module',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/4570',
    },
  },
  'Rich-Text': {
    story: { title: 'DEAL-8', href: 'https://edmunds.atlassian.net/browse/DEAL-8' },
    mr: {
      title: 'Rich Text Module',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/4445',
    },
  },
  'Side-by-Side': {
    story: { title: 'DEAL-6', href: 'https://edmunds.atlassian.net/browse/DEAL-6' },
    mr: {
      title: 'Side by Side Module',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/4259',
    },
  },
  'Feature Demo': {
    story: { title: 'DEAL-11', href: 'https://edmunds.atlassian.net/browse/DEAL-11' },
    mr: {
      title: 'Demo Module',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5240',
    },
  },
  /* No rich-text header */
  'section-header-1': {
    story: { title: 'DEAL-10', href: 'https://edmunds.atlassian.net/browse/DEAL-10' },
    mr: {
      title: 'Section Title w/ Optional CTA',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/4588/',
    },
  },
  'Testimonial Quote': {
    story: { title: 'DEAL-7', href: 'https://edmunds.atlassian.net/browse/DEAL-7' },
    mr: {
      title: 'Testimonial/Quote Module',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/4315',
    },
  },
  'Feature List': {
    story: { title: 'DEAL-14', href: 'https://edmunds.atlassian.net/browse/DEAL-14' },
    mr: {
      title: 'Icon List',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5108',
    },
  },
  'Event Form': {
    story: { title: 'DEAL-23', href: 'https://edmunds.atlassian.net/browse/DEAL-23' },
    mr: { title: 'Event Lead Form', href: ' ' },
  },
  'Lead Form': {
    story: { title: 'DEAL-20', href: 'https://edmunds.atlassian.net/browse/DEAL-20' },
    mr: { title: 'Lead Form', href: ' ' },
  },
  'Feature Grid': {
    story: { title: 'DEAL-15', href: 'https://edmunds.atlassian.net/browse/DEAL-15' },
    mr: {
      title: '3-Up Feature List',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5564',
    },
  },
  'Article List': {
    story: { title: 'DEAL-18', href: 'https://edmunds.atlassian.net/browse/DEAL-18' },
    mr: {
      title: 'Article List',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5489',
    },
  },
  'Promo banner': {
    story: { title: 'DEAL-16', href: 'https://edmunds.atlassian.net/browse/DEAL-16' },
    mr: {
      title: 'Promo Banner',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5594',
    },
  },
  FAQ: {
    story: { title: 'DEAL-19', href: 'https://edmunds.atlassian.net/browse/DEAL-19' },
    mr: {
      title: 'FAQ',
      href: 'https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/5736',
    },
  },
};
