import React from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { MediaTextCarousel } from 'site-modules/industry-center/components/media-text-carousel/media-text-carousel';
import { RichText } from 'site-modules/industry-center/components/rich-text/rich-text';
import { ImageTextCarousel } from 'site-modules/industry-center/components/image-text-carousel/image-text-carousel';
import { Testimonial } from 'site-modules/industry-center/components/testimonial/testimonial';
import { FeatureDemo } from 'site-modules/industry-center/components/feature-demo/feature-demo';
import { SectionCta } from 'site-modules/industry-center/components/section-cta/section-cta';
import { FeatureList } from 'site-modules/industry-center/components/feature-list/feature-list';
import { LeadForm } from 'site-modules/industry-center/components/lead-form/lead-form';
import { FeatureGrid } from 'site-modules/industry-center/components/feature-grid/feature-grid';
import { ShowStoryInfoBox } from 'site-modules/industry-center/utils/story-info-box';
import { ArticleList } from 'site-modules/industry-center/components/article-list/article-list';
import { DynamicArticleList } from 'site-modules/industry-center/components/dynamic-article-list/dynamic-article-list';
import { PromoBanner } from 'site-modules/industry-center/components/promo-banner/promo-banner';
import { Faq } from 'site-modules/industry-center/components/faq/faq';
import { VideoModule } from 'site-modules/industry-center/components/video-module/video-module';
import { TradeInToolDemo } from 'site-modules/industry-center/components/trade-in-tool-demo/trade-in-tool-demo';
import { EmbeddableInfographic } from 'site-modules/shared/components/infographics/embeddable-infographic/embeddable-infographic';
import { ArticleTable } from 'site-modules/shared/components/editorial/article-table/article-table';

const entriesTypes = {
  heroAlt: /hero-alt/,
  richText: /rich-text/,
  sideBySide: /side-by-side/,
  testimonialQuote: /testimonial-quote/,
  featureDemo: /feature-demo/,
  sectionHeader: /section-header/,
  featureList: /feature-list/,
  leadForm: /lead-form/,
  featureGrid: /feature-grid/,
  promoBanner: /promo-banner/,
  articleList: /article-list/,
  dynamicArticleList: /dynamic-article-list/,
  faq: /faq/,
  video: /video/,
  table: /table/,
  tradeInToolDemo: /trade-in-tool-demo/,
  infoGraphic: /info-graphic/,
};

function RegularContainer({ className, children, entry, showInfoBox, fluid }) {
  return (
    <Container fluid={fluid} className={`${className}`}>
      {children}
      {showInfoBox && ShowStoryInfoBox({ entry })}
    </Container>
  );
}

RegularContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  entry: PropTypes.shape({}),
  showInfoBox: PropTypes.bool,
  fluid: PropTypes.bool,
};

RegularContainer.defaultProps = {
  className: '',
  children: null,
  entry: {},
  showInfoBox: false,
  fluid: false,
};

export function renderEntries({ entriesToRender, showInfoBox, pathname }) {
  return entriesToRender
    .reduce((entries, entry, index) => {
      switch (true) {
        case entriesTypes.heroAlt.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'px-0 standard-spacing-first'}
              fluid
            >
              <ImageTextCarousel entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.richText.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <RichText entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.sideBySide.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <MediaTextCarousel entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.testimonialQuote.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <Testimonial entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.featureDemo.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing px-0'}
              fluid
            >
              <FeatureDemo entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.sectionHeader.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <SectionCta entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.featureList.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <FeatureList entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.leadForm.test(entry.id): {
          const childEntries = entry.childEntries;
          if (childEntries) {
            childEntries.forEach(childEntry => {
              entries.push(
                <RegularContainer
                  key={`${childEntry.id}-${index.toString()}`}
                  entry={entry}
                  showInfoBox={showInfoBox}
                  className={'standard-spacing px-0'}
                  fluid
                >
                  <LeadForm entry={entry} contentMetadata={childEntry.contentMetadata} />
                </RegularContainer>
              );
            });
            break;
          }
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing px-0'}
              fluid
            >
              <LeadForm entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.featureGrid.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <FeatureGrid entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.promoBanner.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <PromoBanner entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.dynamicArticleList.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <DynamicArticleList pathname={pathname} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.articleList.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <ArticleList entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.faq.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className={'standard-spacing'}
            >
              <Faq entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.video.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className="standard-spacing"
            >
              <VideoModule entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.table.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className="standard-spacing"
            >
              <ArticleTable entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.tradeInToolDemo.test(entry.id): {
          entries.push(
            <RegularContainer
              key={`${entry.id}-${index.toString()}`}
              entry={entry}
              showInfoBox={showInfoBox}
              className="standard-spacing"
            >
              <TradeInToolDemo entry={entry} />
            </RegularContainer>
          );
          break;
        }
        case entriesTypes.infoGraphic.test(entry.id): {
          entries.push(
            <RegularContainer key={`${entry.id}-${index.toString()}`} className="standard-spacing">
              <EmbeddableInfographic
                imgSrc={entry.metadata('media-image').value()}
                imgAlt={entry.metadata('imageAltText').value()}
                imgUrl={entry.metadata('imageURL').value()}
                clickableUrl={entry.metadata('clickableURL').value()}
                btnText={entry.metadata('buttonTitle').value()}
              />
            </RegularContainer>
          );
          break;
        }
        default:
          break;
      }
      return entries;
    }, [])
    .map(item => item);
}
