import { TrackingEvent } from 'client/tracking/event';
import { IndustryTrackingConstant } from './constant';

const map = {
  nav_sign_in: TrackingEvent.actionCompleted(IndustryTrackingConstant.ACTION_SIGN_IN, IndustryTrackingConstant.LOGIN),
  nav_contact_us: TrackingEvent.actionCompleted(
    IndustryTrackingConstant.ACTION_CONTACT_EDMUNDS,
    IndustryTrackingConstant.CONTACT_US
  ),
};

export const TrackingMap = {
  ...map,
};
