import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';

import './hero-carousel.scss';

export function HeroCarousel({
  id,
  slides,
  children,
  className,
  lazyLoad,
  autoplay,
  autoplaySpeed,
  dots,
  arrows,
  afterChange,
  beforeChange,
  customEventData,
  draggable,
  swipe,
  swipeEvent,
  currentSlideIndex,
  infinite,
  'data-tracking-parent': dataTrackingParent,
}) {
  return (
    <Container
      fluid
      className={classnames('hero-carousel px-0', className)}
      id={id}
      data-tracking-parent={dataTrackingParent}
    >
      {!!children && (
        <Row noGutters className="hero-content">
          <Col>{children}</Col>
        </Row>
      )}

      <Row noGutters>
        <Col>
          {!!get(slides, 'length') && (
            <Carousel
              fluid
              contentType="photo"
              lazyLoad={lazyLoad}
              autoplay={autoplay}
              autoplaySpeed={autoplaySpeed}
              dots={dots}
              arrow={arrows}
              afterChange={afterChange}
              beforeChange={beforeChange}
              customEventData={customEventData}
              draggable={draggable}
              swipe={swipe}
              swipeEvent={swipeEvent}
              currentSlideIndex={currentSlideIndex}
              infinite={infinite}
            >
              {slides}
            </Carousel>
          )}
        </Col>
      </Row>
    </Container>
  );
}

HeroCarousel.propTypes = {
  id: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.element).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  'data-tracking-parent': PropTypes.string.isRequired,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  afterChange: PropTypes.func,
  beforeChange: PropTypes.func,
  customEventData: PropTypes.shape({}),
  draggable: PropTypes.bool,
  swipe: PropTypes.bool,
  swipeEvent: PropTypes.func,
  currentSlideIndex: PropTypes.number,
  infinite: PropTypes.bool,
};

HeroCarousel.defaultProps = {
  id: null,
  lazyLoad: true,
  children: null,
  className: null,
  autoplay: false,
  autoplaySpeed: 3000,
  dots: false,
  arrows: true,
  afterChange: null,
  beforeChange: null,
  customEventData: null,
  draggable: true,
  swipe: true,
  swipeEvent: null,
  currentSlideIndex: 0,
  infinite: true,
};
