import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sticky from 'react-stickynode';
import './sticky-fixed.scss';

export function StickyFixed({ children, classNames }) {
  if (!children) {
    return null;
  }

  return <Sticky className={classnames(classNames, 'sticky-fixed')}>{children}</Sticky>;
}

StickyFixed.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.string,
};

StickyFixed.defaultProps = {
  children: null,
  classNames: null,
};
