import React from 'react';
import PropTypes from 'prop-types';

/**
 * Youtube component
 * @param {Object} props Component properties
 */
export function Youtube(props) {
  const { videoId, autoplay, muted, showRelated, className } = props;

  const url = `//www.youtube.com/embed/${videoId}?autoplay=${autoplay}&mute=${muted}&rel=${showRelated}`;

  return <iframe className={className} src={url} title="Video Player" allowFullScreen />;
}

Youtube.propTypes = {
  autoplay: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
  muted: PropTypes.bool,
  showRelated: PropTypes.number,
  className: PropTypes.string,
};
Youtube.defaultProps = {
  muted: false,
  autoplay: false,
  videoId: '',
  showRelated: 1,
  className: '',
};
