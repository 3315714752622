import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

export function Cta({ ctaTitle, ctaUrl }) {
  return (
    <Button size="md" color="primary" className="cta-btn" href={ctaUrl}>
      <span className="text-white">{ctaTitle}</span>
    </Button>
  );
}

Cta.propTypes = {
  ctaTitle: PropTypes.string,
  ctaUrl: PropTypes.string,
};

Cta.defaultProps = {
  ctaTitle: '',
  ctaUrl: '',
};
