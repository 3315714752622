import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getQuery } from 'client/utils/location';
import { isEmpty, merge, get } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import Button from 'reactstrap/lib/Button';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { formValidation } from 'site-modules/shared/components/form-validation/form-validation';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';
import { externalDataAPI } from 'client/data/api/api-client';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import classNames from 'classnames';
import { Checkbox } from 'site-modules/shared/components/checkbox/checkbox';

import './lead-form.scss';

export const FORM_FIELDS = {
  name: '00N0f00000FtQSs',
  dealerName: '00NG000000DhLpG',
  dealerGroup: '00NPl000003zOVC',
  phoneNumber: '00NG000000Els9f',
  zipCode: '00NG000000Els9h',
  email: '00NG000000Els9c',
  orgId: '00DA0000000IlZt',
  ref: '00N0f00000FtQSq',
  recordType: '012G0000001IonD',
};

const validators = {
  [FORM_FIELDS.name]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included',
  },
  [FORM_FIELDS.dealerName]: {
    test: field => validation.validateMinLength(field, 4),
    errorText: 'Please correct your dealer name.',
  },
  [FORM_FIELDS.phoneNumber]: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number',
    isOptional: true,
  },
  [FORM_FIELDS.zipCode]: {
    test: validation.validateZip,
    errorText: 'Please enter a valid zip code',
    isOptional: true,
  },
  [FORM_FIELDS.email]: {
    test: validation.validateEmail,
    errorText: 'Please enter your correct email address',
    isOptional: true,
  },
};

const ERROR_MESSAGE = 'Please enter a valid phone number or correct email address';

const SITE_URL = 'https://www.edmunds.com';

export function FormErrorMessage({ message }) {
  return !!message && <p className="text-left mb-0_75 medium text-danger">{message}</p>;
}

export function FormHiddenFields({ location, locationHref }) {
  return (
    <Fragment>
      <input type="hidden" name="orgid" value={FORM_FIELDS.orgId} />
      <input type="hidden" name="retURL" value={`${locationHref}?submitted=true`} />
      <input type="hidden" name="recordType" id="recordType" value={FORM_FIELDS.recordType} />
      <input
        id={FORM_FIELDS.ref}
        value={`${SITE_URL}${location.pathname}`}
        maxLength={255}
        name={FORM_FIELDS.ref}
        size={20}
        type="hidden"
      />
    </Fragment>
  );
}
FormHiddenFields.propTypes = {
  location: PropTypes.shape({}),
  locationHref: PropTypes.string,
};
FormHiddenFields.defaultProps = {
  location: {},
  locationHref: '',
};

export class LeadFormUI extends Component {
  static propTypes = {
    entry: CmsEntities.Content.isRequired,
    validationErrors: PropTypes.objectOf(PropTypes.string),
    validate: PropTypes.func.isRequired,
    fieldRef: PropTypes.func.isRequired,
    location: PropTypes.shape({}),
    contentMetadata: PropTypes.shape({
      thankYouMsg: PropTypes.string,
      downloadFileLink: PropTypes.string,
      downloadFileCTA: PropTypes.string,
    }),
  };

  static defaultProps = {
    validationErrors: {},
    location: {},
    contentMetadata: {},
  };

  state = {
    showErrorMessage: false,
    [FORM_FIELDS.phoneNumber]: '',
    [FORM_FIELDS.email]: '',
    metadata: {},
    contentMetadata: {},
  };

  componentDidMount() {
    const {
      metadata: { 'form-cta': formCta },
    } = this.state;
    this.locationHref = window.location.href;
    this.salesForceApiClient = externalDataAPI(formCta);
  }

  static getDerivedStateFromProps(nextProps) {
    const { entry, contentMetadata, location } = nextProps;
    const query = getQuery(location);
    const submitted = get(query, 'submitted', false);
    return {
      metadata: entry.getAllMetadata() || {},
      contentMetadata,
      showSuccess: submitted,
    };
  }

  handleSubmit = e => {
    const isValid = this.props.validate();
    const { [FORM_FIELDS.phoneNumber]: phoneNumber, [FORM_FIELDS.email]: email } = this.state;
    if (!isValid || !(phoneNumber + email)) {
      this.setState({ showErrorMessage: !(phoneNumber + email) });
      e.preventDefault();
    }
    /* TODO: When CORS is enabled for SalesForce API calls then we can enable this. (MEM-2434 & MEM-2386) */
    // this.salesForceApiClient.fetch('', { method: 'POST', body: new FormData(e.target) }).then(() => {
    //   this.setState({ showSuccess: true });
    // });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  renderActionButton = ({ type, buttonColor, buttonTextColor, href, text = 'Submit' }) => {
    const id = 'lead-form-submit';
    const className = 'mt-1_5';
    return buttonColor ? (
      <Button
        href={href}
        className={className}
        type={type}
        id={id}
        style={formatInlineCssProperty(buttonColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)}
      >
        <span style={formatInlineCssProperty(buttonTextColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR)}>{text}</span>
      </Button>
    ) : (
      <Button href={href} color="success" className="mt-1_5" type={type} id={id}>
        {text}
      </Button>
    );
  };

  render() {
    const { validationErrors, fieldRef, location } = this.props;
    const { metadata, contentMetadata, showSuccess } = this.state;
    if (isEmpty(metadata)) return null;

    const {
      'form-header': formHeader,
      'form-subheader': formSubHeader,
      'background-color': backgroundColor,
      'background-image': backgroundImage,
      'button-color': buttonColor,
      'button-text-color': buttonTextColor,
      thankYouMsg = contentMetadata.thankYouMsg,
      downloadFileLink = contentMetadata.downloadFileLink,
      downloadFileCTA = contentMetadata.downloadFileCTA,
      dates,
      discuss,
      about,
      dealerGroup,
      'form-cta': formCta,
    } = metadata;
    const isCommentField = isEmpty(dates);
    const isAboutField = !isEmpty(about);
    const isDiscussField = !isEmpty(discuss);
    const isDealerGroupField = !isEmpty(dealerGroup);
    const firstDiscussElement = 'What would you like to discuss? Select all that apply:';

    return (
      <div
        className={classNames('lead-form banner-image', { 'with-comment-field': isCommentField })}
        style={merge(
          formatInlineCssProperty(backgroundColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR),
          formatInlineCssProperty(backgroundImage, CSS_PROPERTY_CONSTANTS.BACKGROUND_IMAGE)
        )}
      >
        <Container>
          <Row className="text-white text-center justify-content-center pb-2 no-gutters">
            <Col xs={12} sm={10} md={8} lg={6}>
              {showSuccess ? (
                <div className="form-container">
                  <div className="heading-4 text-white pb-2 pt-3 sub-title">
                    <ContentFragment>{thankYouMsg}</ContentFragment>
                    {downloadFileLink &&
                      this.renderActionButton({
                        href: downloadFileLink,
                        text: downloadFileCTA,
                        buttonColor,
                        buttonTextColor,
                      })}
                  </div>
                </div>
              ) : (
                <Form
                  className="px-1 px-md-0 form-container"
                  noValidate
                  onSubmit={this.handleSubmit}
                  method="POST"
                  action={formCta}
                >
                  <div className="heading-2 text-white pt-3 title">{formHeader}</div>
                  <div className="heading-4 text-white pb-2 pt-1_5 sub-title">{formSubHeader}</div>
                  <FormGroup>
                    <FormField
                      id={FORM_FIELDS.name}
                      maxLength={255}
                      name={FORM_FIELDS.name}
                      size={20}
                      label=""
                      type="text"
                      placeholder="Name"
                      ref={field => fieldRef(FORM_FIELDS.name, field)}
                      isValid={!(FORM_FIELDS.name in validationErrors)}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.name]} />
                    <FormField
                      id={FORM_FIELDS.dealerName}
                      maxLength={255}
                      name={FORM_FIELDS.dealerName}
                      size={20}
                      label=""
                      type="text"
                      placeholder="Dealer name"
                      ref={field => fieldRef(FORM_FIELDS.dealerName, field)}
                      isValid={!(FORM_FIELDS.dealerName in validationErrors)}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.dealerName]} />

                    {isDealerGroupField && (
                      <FormField
                        id={FORM_FIELDS.dealerGroup}
                        maxLength={255}
                        name={FORM_FIELDS.dealerGroup}
                        size={20}
                        label=""
                        type="text"
                        placeholder="Dealer group name"
                        ref={field => fieldRef(FORM_FIELDS.dealerGroup, field)}
                      />
                    )}

                    <Row>
                      <Col xs={6}>
                        <FormField
                          id={FORM_FIELDS.phoneNumber}
                          maxLength={40}
                          name={FORM_FIELDS.phoneNumber}
                          size={20}
                          label=""
                          type="text"
                          placeholder="Cell phone number"
                          ref={field => fieldRef(FORM_FIELDS.phoneNumber, field)}
                          isValid={!(FORM_FIELDS.phoneNumber in validationErrors)}
                          onChange={this.handleChange}
                        />
                        <FormErrorMessage message={validationErrors[FORM_FIELDS.phoneNumber]} />
                      </Col>
                      <Col xs={6}>
                        <FormField
                          id={FORM_FIELDS.zipCode}
                          maxLength={10}
                          name={FORM_FIELDS.zipCode}
                          size={20}
                          label=""
                          type="text"
                          placeholder="Zip code"
                          ref={field => fieldRef(FORM_FIELDS.zipCode, field)}
                          isValid={!(FORM_FIELDS.zipCode in validationErrors)}
                        />
                        <FormErrorMessage message={validationErrors[FORM_FIELDS.zipCode]} />
                      </Col>
                    </Row>
                    <FormField
                      id={FORM_FIELDS.email}
                      maxLength={80}
                      size={20}
                      name={FORM_FIELDS.email}
                      placeholder="Email address"
                      ref={field => fieldRef(FORM_FIELDS.email, field)}
                      label=""
                      type="text"
                      isValid={!(FORM_FIELDS.email in validationErrors)}
                      onChange={this.handleChange}
                    />
                    <FormErrorMessage message={validationErrors[FORM_FIELDS.email]} />

                    {this.state.showErrorMessage && (
                      <p className="text-left my-1 medium text-danger">{ERROR_MESSAGE}</p>
                    )}

                    {isCommentField ? (
                      <Input name="description" type="textarea" placeholder="Comment" maxLength={500} rows={3} />
                    ) : (
                      <Input name="description" type="select" placeholder="Select a time" className="mb-0_75">
                        {dates.split(';').map(val => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </Input>
                    )}

                    {isAboutField && (
                      <Input
                        name="description"
                        type="select"
                        placeholder="How did you hear about us?"
                        className="mb-0_75"
                      >
                        {about.split(';').map(val => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </Input>
                    )}
                    {isDiscussField && (
                      <div className="checkbox mt-0_75">
                        <span>{firstDiscussElement}</span>
                        {discuss
                          .split(';')
                          .slice(1)
                          .map(val => (
                            <Checkbox
                              name="description"
                              id={discuss.indexOf(val)}
                              key={discuss.indexOf(val)}
                              containerClassName="pos-r text-left"
                              labelText={val}
                              value={val}
                              textLabelClassName="text-white ml-1_25"
                            />
                          ))}
                      </div>
                    )}

                    {this.renderActionButton({ type: 'Submit', buttonColor: 'FFFFFF', buttonTextColor: '396fe0' })}
                  </FormGroup>
                  <FormHiddenFields location={location} locationHref={this.locationHref} />
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export const LeadForm = withRouter(formValidation(LeadFormUI, validators));
