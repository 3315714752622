import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { get, set, orderBy } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { SitemapModel, SitemapEntities, buildPagePath } from 'client/data/models/sitemap';
import { createDate, getMonth, getCurrentDate } from 'client/site-modules/shared/utils/date-utils';

import './dynamic-article-list.scss';

function DynamicArticleListSection({ heading, links }) {
  return (
    <section className="mb-2">
      <h3 className="heading-3 size-16 text-center mb-1_5">{heading}</h3>
      <ul className="list-unstyled mb-0">
        {orderBy(links, [({ published }) => new Date(published)], ['desc']).map(({ location, title, published }) => (
          <li key={location} className="mb-0_75">
            <a href={`${location}.html`} className="article-list-link text-primary-darker size-16">
              <span className="control-text">{title}</span>
              {published && <span className="date text-gray-darker"> - {dateFormat(published, 'mm/dd/yyyy')}</span>}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
}

DynamicArticleListSection.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(SitemapEntities.SitemapLink),
};

DynamicArticleListSection.defaultProps = {
  heading: '',
  links: [],
};

export function sortSectionByYears([sectionYear1], [sectionYear2]) {
  return sectionYear1 > sectionYear2 ? -1 : 1;
}

export function DynamicArticleListUI({ articlesLinks }) {
  const currentYear = getCurrentDate().getFullYear();
  const sections = articlesLinks.reduce((result, link) => {
    const articleDate = createDate(link.published);
    const articleMonth = getMonth(articleDate);
    const articleYear = articleDate.getFullYear();
    const sectionLinksNum = get(result, [articleYear, articleMonth, 'length']);
    const linkInd = sectionLinksNum || 0;

    if (articleYear >= currentYear - 2) {
      set(result, [articleYear, articleMonth, linkInd], link);
    }

    return result;
  }, {});

  const sortedSections = Object.entries(sections).sort(sortSectionByYears);

  return (
    <section className="dynamic-article-list" aria-label="Latest news articles">
      {sortedSections.map(([year, monthData]) => (
        <section key={year} className="mb-2">
          <h2 className="heading-3 mb-1_75 mb-0">{year}</h2>
          {Object.entries(monthData).map(([month, links]) => (
            <DynamicArticleListSection key={month} heading={month} links={links} />
          ))}
        </section>
      ))}
    </section>
  );
}

DynamicArticleListUI.propTypes = {
  articlesLinks: PropTypes.arrayOf(SitemapEntities.SitemapLink),
};

DynamicArticleListUI.defaultProps = {
  articlesLinks: [],
};

export const stateToPropsConfig = {
  articlesLinks: bindToPath(({ pathname }) => buildPagePath(`${pathname}sitemap.html`), SitemapModel, data =>
    get(data, 'results', [])
  ),
};

export const DynamicArticleList = connectToModel(DynamicArticleListUI, stateToPropsConfig);
