import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { merge } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';

export function createAlignmentClass(className, regularText) {
  if (className && !regularText) {
    return `text-${className}`;
  } else if (className && regularText) {
    return `text-md-${className}`;
  }
  return '';
}

export function RichText({ entry }) {
  if (!entry) {
    return null;
  }
  const contentEntries = entry.getAllMetadata();
  if (!(contentEntries && Object.keys(contentEntries).length)) {
    return null;
  }
  const {
    'header-text': headerText,
    'header-align': headerAlign,
    'subheader-text': subheaderText,
    'subheader-text-align': subheaderTextAlign,
    'regular-text': regularText,
    'regular-text-align': regularTextAlign,
    'text-color': textColor,
    'background-color': backgroundColor,
    'padding-top': paddingTop,
    'padding-bottom': paddingBottom,
  } = contentEntries;
  return (
    <div
      className="rich-text-container text-gray-darker"
      style={merge(
        formatInlineCssProperty(textColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR),
        formatInlineCssProperty(backgroundColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR),
        paddingTop && { paddingTop },
        paddingBottom && { paddingBottom }
      )}
    >
      <main
        id="main-content"
        className={classNames('rich-text-wrapper mt-1 px-1', { 'd-flex flex-column': !!regularText })}
      >
        {headerText && (
          <ContentFragment classes={classNames(createAlignmentClass(headerAlign, regularText), contentEntries.header)}>
            {headerText}
          </ContentFragment>
        )}
        {subheaderText && (
          <ContentFragment
            classes={classNames(createAlignmentClass(subheaderTextAlign, regularText), contentEntries.subheader)}
          >
            {subheaderText}
          </ContentFragment>
        )}
        {regularText && (
          <div className="regular-text">
            <ContentFragment classes={classNames(createAlignmentClass(regularTextAlign, regularText))}>
              {regularText}
            </ContentFragment>
          </div>
        )}
      </main>
    </div>
  );
}

RichText.propTypes = {
  entry: PropTypes.shape({}),
};

RichText.defaultProps = {
  entry: null,
};
