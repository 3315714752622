import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { LINKS } from 'site-modules/shared/components/footer/footer-data';

import './industry-center-footer.scss';

export function IndustryCenterFooter() {
  return (
    <div
      className="container-fluid industry-center-footer pt-1_5 pt-md-3 pb-3 bg-faded"
      data-tracking-parent="edm-entry-industry-center-footer"
    >
      <div className="container" role="contentinfo">
        <Row className="footer-links text-center mt-1_25">
          <Col xs={12} className="px-0">
            <ul className="list-wrapping list-unstyled small mb-0">
              {LINKS.map(({ href, text }) => (
                <li key={text} className="footer-link-item d-inline-block px-0_75 mb-0_75">
                  <a href={href}>{text}</a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        <Row className="copyright text-center mb-2_5">
          <Col xs={12}>
            <div className="small mt-0_5">
              <a href="/" className="ml-0_25 ml-md-0_5 pt-0_5 pt-md-0">
                &copy;Edmunds.com, Inc.
              </a>
              , a wholly owned subsidiary of CarMax, Inc. By using edmunds.com, you consent to the monitoring and
              storing of your interactions with the website, including by an Edmunds vendor, for use in improving and
              personalizing our services. See our <a href="/about/privacy.html">Privacy Statement</a> for details.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
