import React from 'react';
import PropTypes from 'prop-types';

import { HeroCarousel } from 'site-modules/shared/components/hero-carousel/hero-carousel';
import { positionEntries } from 'client/data/cms/content';
import { TestimonialSlide } from './testimonial-slide';
import './testimonial.scss';

export function Testimonial({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }
  const slidesContent = positionEntries(entry.children());
  const slides = slidesContent.map((slideItem, index) => (
    <div key={slideItem.id} className="slide" data-index={index}>
      <TestimonialSlide slideItem={slideItem.getAllMetadata()} />
    </div>
  ));

  return (
    <div className="testimonial carousel-wrapper">
      <div className="testimonial-carousel">
        <HeroCarousel
          slides={slides}
          data-tracking-parent="testimonial-tracking"
          arrows={!!(slidesContent.length - 1)}
        />
      </div>
    </div>
  );
}

Testimonial.propTypes = {
  entry: PropTypes.shape({}),
};

Testimonial.defaultProps = {
  entry: null,
};
