import PropTypes from 'prop-types';
import React from 'react';

export function IFrameEmbed({ src }) {
  return <iframe src={src} className="video-player" title="Video Player" allowFullScreen />;
}

IFrameEmbed.propTypes = {
  src: PropTypes.string,
};

IFrameEmbed.defaultProps = {
  src: '',
};
