import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { Youtube } from './youtube/youtube';
import { EmbeddedYoutube } from './embedded-youtube/embedded-youtube';
import { IFrameEmbed } from './iframe-embed/iframe-embed';

import './index.scss';

const PLAYERS = { Youtube, EmbeddedYoutube, IFrameEmbed };

export function Video(props) {
  const { type, containerClassName, children } = props;
  const Player = PLAYERS[type];

  if (!Player) {
    return null;
  }

  return (
    <RenderWhenViewable verticalOffset="100%">
      <div
        className={classnames(containerClassName, 'pos-r', {
          'video-player-container': type !== 'EmbeddedYoutube',
        })}
      >
        <Player {...props} className="video-player" />
        {children}
      </div>
    </RenderWhenViewable>
  );
}

Video.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
};

Video.defaultProps = {
  type: null,
  muted: true,
  playlistId: '',
  videoId: '',
  src: '',
  children: null,
  containerClassName: '',
};
