import React from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { isEmpty } from 'lodash';
import { StickyFixed } from 'client/site-modules/shared/components/sticky-fixed/sticky-fixed';
import { SubNavigationBase } from 'site-modules/shared/components/sub-navigation-base/sub-navigation-base';

export function SubNavigation(props) {
  const { links } = props;
  const subLinks = links.map(link => ({
    name: link.title,
    href: link.href,
  }));

  return <SubNavigationBase list={subLinks} />;
}

SubNavigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})),
};
SubNavigation.defaultProps = {
  links: [],
};

export function IndustryPageSubNavigation({ subNav }) {
  const links = subNav.links();
  if (isEmpty(links)) return null;

  return (
    <Container fluid className="px-0">
      <StickyFixed>
        <SubNavigation links={links} />
      </StickyFixed>
    </Container>
  );
}

IndustryPageSubNavigation.propTypes = {
  subNav: PropTypes.shape({}),
};

IndustryPageSubNavigation.defaultProps = {
  subNav: {},
};
