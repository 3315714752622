import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import classNames from 'classnames';
import { CmsEntities } from 'client/data/models/cms';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { MediaContentWrapper } from 'client/site-modules/industry-center/components/video-image-showcase/media-content-wrapper';
import {
  TEXTUAL_CONTENT_ID,
  VIDEO_CONTENT_ID,
} from 'client/site-modules/industry-center/components/video-image-showcase/constants';
import { merge } from 'lodash';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';

import './feature-demo.scss';

export function FeatureDemo({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }

  const textContent = entry.child(TEXTUAL_CONTENT_ID);
  const videoContent = entry.child(VIDEO_CONTENT_ID);
  const {
    'text-position-left': textPosition,
    'background-color': backgroundColor,
    'text-color': textContentColor,
    'demo-text': text = '',
  } = textContent.getAllMetadata();
  const demoDevice = getStaticImageUrl('/img/industry-center/five-star-dealers/2023/iphone-14-no-bar.png');
  const leftTextPosition = textPosition && JSON.parse(textPosition);
  return (
    <div
      style={merge(
        formatInlineCssProperty(textContentColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR),
        formatInlineCssProperty(backgroundColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)
      )}
    >
      <Container>
        <Row
          className={classNames('justify-content-between flex-wrap-reverse mx-0', {
            'flex-row': leftTextPosition,
            'flex-row-reverse': !leftTextPosition,
          })}
        >
          <Col xs={12} md={7} lg={8}>
            <ContentFragment classes="mt-2 mt-md-3 ml-md-3 px-sm-1 pt-2_5 px-1 px-md-3 pl-md-3">{text}</ContentFragment>
          </Col>
          <Col xs={12} md={5} lg={4} className="mobileDeviceContainer">
            <div className="mobileDevice pos-r mt-2 mt-md-3 d-flex justify-content-center align-items-center">
              <div className={classNames('mediaContainer', { noContent: !videoContent })}>
                {videoContent && <MediaContentWrapper media={videoContent} className="mediaContainer" />}
              </div>
              <img src={demoDevice} alt={'device'} className="iphonex-z-index" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

FeatureDemo.propTypes = {
  entry: CmsEntities.Content,
};

FeatureDemo.defaultProps = {
  entry: null,
};
