import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classNames from 'classnames';
import { get } from 'lodash';
import { MediaContent } from './media-content/media-content';
import { TextContent } from './text-content/text-content';

export function MediaTextCarouselCard({ content }) {
  if (!content) {
    return null;
  }

  const mediaAlign = get(content, 'media-align', 'right');
  return (
    <Row
      className={classNames('media-text-carousel-card align-items-center', {
        'flex-row-reverse': mediaAlign !== 'right',
      })}
    >
      <Col xs={12} md={5}>
        <MediaContent media={content} />
      </Col>
      <Col xs={12} md={7}>
        <TextContent textualContent={content} />
      </Col>
    </Row>
  );
}

MediaTextCarouselCard.propTypes = {
  content: PropTypes.shape({}),
};

MediaTextCarouselCard.defaultProps = {
  content: null,
};
