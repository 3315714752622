import { parseContent } from 'client/data/cms/content';
import { getMeta } from 'site-modules/shared/utils/seo-helper';
import { getIndustryArticleBreadcrumbs } from 'client/site-modules/industry-center/utils/utils';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';

export function getSeoObject(state, contentPathInState, pathname) {
  const {
    cms: { content },
  } = state;

  if (!content) {
    return {};
  }
  const articleContent = parseContent(content[contentPathInState]);
  const meta = getMeta(articleContent);
  const socialImageUrl = articleContent.metadata('socialImage').value();
  if (socialImageUrl) meta.image = { url: socialImageUrl };

  return {
    ...meta,
    jsonld: [getBreadcrumbSchema(getIndustryArticleBreadcrumbs(pathname, articleContent.title))],
  };
}
