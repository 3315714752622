import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

export const PLAY_EVENT = 'embedded-youtube_play';
export const PAUSE_EVENT = 'embedded-youtube_pause';

export function createPlayerStateChangeHandler(seed) {
  let hasBeenStarted;
  let adRefreshFlag = false;
  let pauseFired = false;
  const VIDEO_VIEWER_SIZE = 'video_viewer_size';
  const { SYSTEM_ACTION_CATEGORY, USER_ACTION_CATEGORY, VIDEO_START, VIDEO_END, VIDEO_PAUSE } = TrackingConstant;

  function buildActionObject(actionCategory, subactionName, trackingValue, disableAdRefresh) {
    const actionName =
      actionCategory === SYSTEM_ACTION_CATEGORY
        ? TrackingConstant.ACTION_SHOW_CONTENT
        : TrackingConstant.ACTION_VIEW_CONTENT;
    return {
      event_type: 'action_completed',
      ads: {
        disable_refresh: disableAdRefresh,
      },
      event_data: {
        action_category: actionCategory,
        action_name: actionName,
        action_cause: 'button_click',
        subaction_name: subactionName,
        creative_id: 'long-video',
        value: trackingValue,
      },
    };
  }

  return event => {
    const playerState = window.YT.PlayerState;
    const { title, video_id: videoId } = event.target.getVideoData();
    const trackingValue = `${title} | ID ${videoId}`;

    if (event.data === playerState.PLAYING && !hasBeenStarted) {
      EventToolbox.fireCustomEvent(PLAY_EVENT, { seed });
      EventToolbox.fireTrackAction(buildActionObject(USER_ACTION_CATEGORY, VIDEO_START, trackingValue, false));
      EventToolbox.fireTrackAction(
        buildActionObject(USER_ACTION_CATEGORY, VIDEO_VIEWER_SIZE, window.screen.width, true)
      );
      hasBeenStarted = true;
    } else if (event.data === playerState.PLAYING && hasBeenStarted) {
      if (pauseFired) {
        EventToolbox.fireCustomEvent(PLAY_EVENT, { seed });
        EventToolbox.fireTrackAction(
          buildActionObject(USER_ACTION_CATEGORY, VIDEO_START, trackingValue, adRefreshFlag)
        );
      }
      adRefreshFlag = false;
      pauseFired = false;
    } else if (event.data === playerState.ENDED) {
      EventToolbox.fireTrackAction(buildActionObject(SYSTEM_ACTION_CATEGORY, VIDEO_END, trackingValue, false));
      pauseFired = true; // Needed for replay
    } else if (event.data === playerState.PAUSED) {
      EventToolbox.fireTrackAction(buildActionObject(USER_ACTION_CATEGORY, VIDEO_PAUSE, trackingValue, false));
      EventToolbox.fireCustomEvent(PAUSE_EVENT);
      pauseFired = true;
    } else if (event.data === playerState.BUFFERING && hasBeenStarted) {
      adRefreshFlag = true;
    }
  };
}
