import React from 'react';
import PropTypes from 'prop-types';

import { HeroCarousel } from 'site-modules/shared/components/hero-carousel/hero-carousel';
import { positionEntries } from 'client/data/cms/content';
import { ImageTextSlide } from './image-text-slide';
import { DEFAULT_CAROUSEL_SPEED } from './constants';
import './image-text-carousel.scss';

export function ImageTextCarousel({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }
  const slidesContent = positionEntries(entry.children());
  const speed = entry.metadata('carousel-speed').value() || DEFAULT_CAROUSEL_SPEED;
  const slides = slidesContent.map((slideItem, index) => (
    <div key={slideItem.id} className="slide" data-index={index}>
      <ImageTextSlide slideItem={slideItem.getAllMetadata()} />
    </div>
  ));
  const hasMultipleSlide = !!(slidesContent.length - 1);
  return (
    <div className="image-text-carousel-container carousel-wrapper">
      <div className="image-text-carousel-wrapper">
        <HeroCarousel
          slides={slides}
          data-tracking-parent="image-text-carousel"
          arrows={hasMultipleSlide}
          dots={hasMultipleSlide}
          autoplay={hasMultipleSlide}
          autoplaySpeed={speed}
          className="no-gradient"
        />
      </div>
    </div>
  );
}

ImageTextCarousel.propTypes = {
  entry: PropTypes.shape({}),
};

ImageTextCarousel.defaultProps = {
  entry: null,
};
