import React from 'react';
import PropTypes from 'prop-types';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Cta } from 'site-modules/industry-center/components/shared/cta/cta';

export function TextContent({ textualContent }) {
  const { header, text, button, 'button-title': ctaTitle, 'button-url': ctaUrl } = textualContent;
  return (
    <div className="px-1_25 py-1 px-md-3 py-md-0">
      {header && (
        <ContentFragment componentToUse="h2" classes={['display-2', 'title', 'mb-1']}>
          {header}
        </ContentFragment>
      )}
      {text && (
        <ContentFragment componentToUse="p" classes={['display-5']}>
          {text}
        </ContentFragment>
      )}
      {button && ctaTitle && (
        <div className="pt-0_5">
          <Cta ctaTitle={ctaTitle} ctaUrl={ctaUrl} />
        </div>
      )}
    </div>
  );
}

TextContent.propTypes = {
  textualContent: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    button: PropTypes.string,
    'button-title': PropTypes.string,
    'button-url': PropTypes.string,
  }).isRequired,
};
