import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { parseContent } from 'client/data/cms/content';
import { VideoModuleUI } from 'site-modules/shared/components/video-module/video-module';

const VIDEO_WIDTH_LARGE = 8;
export function VideoModule({ entry }) {
  const meta = entry.getAllMetadata();
  const playlistTitle = get(meta, 'playlistTitle', '');
  const videos = parseContent(entry)
    .children()
    .map(child => child.getAllMetadata());
  return <VideoModuleUI videos={videos} playlistTitle={playlistTitle} videoLargeColumnSize={VIDEO_WIDTH_LARGE} />;
}

VideoModule.propTypes = {
  entry: PropTypes.shape({}),
};

VideoModule.defaultProps = {
  entry: null,
};
