import { get } from 'lodash';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'client/site-modules/shared/components/breadcrumbs/helper';
import { CSS_PROPERTY_CONSTANTS } from './constants';

const HEX_COLOR_REGEXP = /^([0-9a-f]{6}|[0-9a-f]{3})$/i;
const formatHex = hex => `#${hex}`;
const formatImageUrl = url => `url(${url})`;
const testHexColor = hex => HEX_COLOR_REGEXP.test(hex);

const propertyType = {
  [CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR]: hex => (testHexColor(hex) ? { backgroundColor: formatHex(hex) } : null),
  [CSS_PROPERTY_CONSTANTS.TEXT_COLOR]: hex => (testHexColor(hex) ? { color: formatHex(hex) } : null),
  [CSS_PROPERTY_CONSTANTS.BACKGROUND_IMAGE]: url => ({ backgroundImage: formatImageUrl(url) }),
  [CSS_PROPERTY_CONSTANTS.HEIGHT]: height => ({ height }),
};

export const formatInlineCssProperty = (value, type) => (value ? propertyType[type](value) : null);

export function getHeader(entry, header) {
  const gridHeaderInfo = entry.child(header).getAllMetadata();
  return {
    headerText: get(gridHeaderInfo, 'header-text', ''),
    subHeaderText: get(gridHeaderInfo, 'subheader-text', ''),
  };
}

/**
 * Returns iterable items for displaying in a component
 * @param entry - all entries for a component
 * @param header - header description id for removing
 * @return {*}
 */
export function getItems(entry, header) {
  return entry.children().filter(item => item.id !== header);
}

export function getIndustryArticleBreadcrumbs(pathname, title) {
  return [
    getHomeBreadcrumb(),
    getBaseBreadcrumb('Industry', '/industry/'),
    getBaseBreadcrumb('Press', '/industry/press/'),
    getBaseBreadcrumb(title, pathname),
  ];
}
