import React from 'react';

import { HeroCarousel } from 'site-modules/shared/components/hero-carousel/hero-carousel';
import { CmsEntities } from 'client/data/models/cms';
import { positionEntries } from 'client/data/cms/content';
import { MediaTextCarouselCard } from './media-text-carousel-card';

import './media-text-carousel.scss';

export function MediaTextCarousel({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }

  const slidesContent = positionEntries(entry.children());
  const slides = slidesContent.map((slideItem, index) => (
    <div key={slideItem.id} className="slide" data-index={index}>
      <MediaTextCarouselCard content={slideItem.getAllMetadata()} key={slideItem.id} data-index={index} />
    </div>
  ));

  const showArrows = !!(slides.length - 1);
  return (
    <div className="side-by-side-carousel">
      <HeroCarousel slides={slides} data-tracking-parent="side-by-side-tracking" arrows={showArrows} />
    </div>
  );
}

MediaTextCarousel.propTypes = {
  entry: CmsEntities.Content,
};

MediaTextCarousel.defaultProps = {
  entry: null,
};
