export function parseVideoProps(media) {
  if (media['static-video']) {
    return {
      type: 'HtmlVideoPlayer',
      src: media['static-video'],
    };
  } else if (media['youtube-videoID']) {
    return {
      type: 'Youtube',
      videoId: media['youtube-videoID'],
    };
  }

  return null;
}
