import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Video } from 'site-modules/shared/components/video';
import { HtmlVideoPlayer } from 'site-modules/shared/components/html-video-player/html-video-player';
import { STATIC_VIDEO_TYPE } from './constants';

import './media-content-wrapper.scss';

export function MediaContentWrapper({ media }) {
  const mediaProps = media.getAllMetadata();

  const { type, videoId, thumbnailURL, 'static-video': staticVideo } = mediaProps;
  const videoType = capitalize(type);
  return (
    <Fragment>
      {!videoId && (
        <Fragment>
          {thumbnailURL && (
            <div className="slide-img-content d-flex justify-content-center align-items-center align-self-center">
              <img className="w-100" src={thumbnailURL} alt={media.id} />
            </div>
          )}
          {staticVideo && <HtmlVideoPlayer src={staticVideo} autoPlay loop />}
        </Fragment>
      )}
      {videoId && (
        <div className="w-100">
          {videoType !== STATIC_VIDEO_TYPE && (
            <Video videoId={videoId} type={videoType} showRelated={0} className={'h-100'} creativeId={media.id} />
          )}
          {videoType === STATIC_VIDEO_TYPE && <HtmlVideoPlayer src={videoId} poster={thumbnailURL} autoPlay loop />}
        </div>
      )}
    </Fragment>
  );
}

MediaContentWrapper.propTypes = {
  media: PropTypes.shape({
    type: PropTypes.string,
    thumbnailURL: PropTypes.string,
    videoId: PropTypes.string,
  }).isRequired,
};
