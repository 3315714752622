import React from 'react';
import Button from 'reactstrap/lib/Button';
import { isEmpty, merge } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CmsEntities } from 'client/data/models/cms';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';

import './promo-banner.scss';

export function PromoBanner({ entry }) {
  const bannerEntry = entry.getAllMetadata();
  if (isEmpty(bannerEntry)) {
    return null;
  }

  const {
    'banner-background-color': backgroundColor,
    'banner-background-image': backgroundImage,
    'banner-cta-color': ctaColor,
    'banner-cta-link': ctaLink,
    'banner-cta-text': ctaText,
    'banner-cta-text-color': ctaTextColor,
    'banner-image': bannerImage,
    'banner-name': bannerName,
    'banner-height': bannerHeight,
    'banner-text-color': bannerTextColor,
  } = bannerEntry;

  return (
    <div
      className="d-flex flex-column justify-content-start flex-md-row justify-content-md-between align-items-start align-items-md-center promo-banner banner-image pl-2_5"
      style={merge(
        formatInlineCssProperty(backgroundColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR),
        formatInlineCssProperty(backgroundImage, CSS_PROPERTY_CONSTANTS.BACKGROUND_IMAGE),
        formatInlineCssProperty(bannerHeight, CSS_PROPERTY_CONSTANTS.HEIGHT)
      )}
    >
      {bannerImage && <img className="py-1 rounded-circle" src={bannerImage} alt="banner" />}
      {bannerName && (
        <ContentFragment
          componentToUse="p"
          classes="heading-4 w-100 pl-0 pl-md-2"
          inlineStyle={formatInlineCssProperty(bannerTextColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR)}
        >
          {bannerName}
        </ContentFragment>
      )}
      {ctaLink && (
        <Button
          style={formatInlineCssProperty(ctaColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)}
          className="mx-0 mx-md-2 mb-2 mb-md-0"
          href={ctaLink}
        >
          <span style={formatInlineCssProperty(ctaTextColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR)}>{ctaText}</span>
        </Button>
      )}
    </div>
  );
}

PromoBanner.defaultProps = {
  entry: null,
};
PromoBanner.propTypes = {
  entry: CmsEntities.Content,
};
